const mq = {
  mobile: {
    small: '375px',
    medium: '425px',
    large: '768px',
  },
  desktop: {
    small: '1024px',
    medium: '1200px',
    large: '1540px',
  },
};

export default mq;
